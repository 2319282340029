<template>
  <div class="contents-area faq-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ translations("FaqPage") }}</h1>
          <Breadcrumbs />
        </div>
      </div>
      <FaqAccordeon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FaqAccordeon from "@/components/app/FaqAccordeon";

export default {
  name: "Faq",
  components: { FaqAccordeon },
  metaInfo() {
    return {
      title: this.translations("FaqPage"),
    };
  },
  computed: {
    ...mapGetters(["translations"]),
  },
};
</script>
